<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Company Center Detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="CompanyCenterDetailForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="CompanyCenter.code"
            name="Code"
            label="Code"
            :rules="{ required: true }"
            trim
          />
          <TextFieldInput
            v-model="CompanyCenter.name"
            name="Name"
            label="Name"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="CompanyCenter.remark"
            name="Remark"
            label="Remark"
            trim />
          <SelectField
            v-model="CompanyCenter.companyGroupId"
            :options="companyGroupOptions"
            name="Company Group"
            form-label="Company Group"
            label-option="name"
            return-value="id" />
          <SelectField
            v-model="CompanyCenter.companyDepartmentId"
            :options="companyDepartmentOptions"
            name="Company Department"
            form-label="Company Department"
            label-option="name"
            return-value="id" />
          <StatusRadioButton :status.sync="CompanyCenter.status" />
          <b-row>
            <b-col>
              Created At : {{ CompanyCenter.createdAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Created By : {{ CompanyCenter.createdBy.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated At : {{ CompanyCenter.updatedAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated By : {{ CompanyCenter.updatedBy.username }}
            </b-col>
          </b-row>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit"
              :disabled="compareOldValue">
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              :disabled="compareOldValue"
              @click="resetForm()">
              Cancel
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-auto"
              :disabled="!compareOldValue"
              @click="deleteItem ()">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import SelectField from '@/components/Form/SelectField.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'

export default {
  components: {
    SelectField,
    TextFieldInput,
    StatusRadioButton
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialCompanyCenter: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    },
    companyGroupOptions: {
      type: Array,
      default: () => []
    },
    companyDepartmentOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
    CompanyCenter: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return JSON.stringify(this.CompanyCenter) === JSON.stringify(this.initialCompanyCenter)
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      const payload = {
        code: this.CompanyCenter.code,
        id: this.CompanyCenter.id,
        name: this.CompanyCenter.name,
        remark: this.CompanyCenter.remark,
        companyGroupId: this.CompanyCenter.companyGroupId,
        companyDepartmentId: this.CompanyCenter.companyDepartmentId,
        status: this.CompanyCenter.status
      }
      this.$emit('update-company-center', payload)
    },
    resetForm () {
      this.CompanyCenter = { ...this.initialCompanyCenter }
      this.$refs.CompanyCenterDetailForm.reset()
    },
    deleteItem () {
      this.$emit('delete-item', {
        ID: this.CompanyCenter.id,
        Name: this.CompanyCenter.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-submit {
  min-width: 134px;
}
</style>
