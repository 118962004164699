<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <CompanyCenterAdd
      ref="companyCenterAdd"
      :is-sidebar-active.sync="isSidebarActive"
      :company-group-options="companyGroup"
      :company-department-options="companyDepartment"
      @add-company-center="addCompanyCenter($event)" />
    <CompanyCenterDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-company-center="initialCompanyCenter"
      :selected-item.sync="selectedItem"
      :company-group-options="companyGroup"
      :company-department-options="companyDepartment"
      @update-company-center="updateCompanyCenter($event)"
      @delete-item="openDeleteModal($event)" />
    <CompanyCenterFilter
      :search.sync="filter.searchCodeAndName"
      :status-filter.sync="filter.status"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Company Center"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @row-clicked="rowClick($event)">
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(name)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import CompanyCenterAdd from '@/components/Customers/CompanyCenter/CompanyCenterAdd.vue'
import CompanyCenterDetail from '@/components/Customers/CompanyCenter/CompanyCenterDetail.vue'
import CompanyCenterFilter from '@/components/Customers/CompanyCenter/CompanyCenterFilter.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import TableTop from '@/components/TableTop.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CompanyCenterProvider from '@/resources/CompanyCenterProvider'
import CompanyGroupProvider from '@/resources/CompanyGroupProvider'
import CompanyDepartmentProvider from '@/resources/CompanyDepartmentProvider'

const CompanyCenterService = new CompanyCenterProvider()
const CompanyGroupService = new CompanyGroupProvider()
const CompanyDepartmentService = new CompanyDepartmentProvider()

export default {
  components: {
    CompanyCenterAdd,
    CompanyCenterDetail,
    CompanyCenterFilter,
    DeleteModal,
    TableTop
  },
  data () {
    return {
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchCodeAndName: '',
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'remark', label: 'Remark' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      initialCompanyCenter: {},
      selectedItem: {},
      deleteItem: {},
      companyGroup: [],
      companyDepartment: []
    }
  },
  created () {
    this.getCompanyCenter()
  },
  methods: {
    async getCompanyCenter () {
      try {
        const data = await CompanyCenterService.paginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async getCompanyGroup () {
      try {
        const { data } = await CompanyGroupService.paginate(1, 99999)
        this.companyGroup = data
      } catch (err) {
        console.error(err)
      }
    },
    async getCompanyDepartment () {
      try {
        const { data } = await CompanyDepartmentService.paginate(1, 99999)
        this.companyDepartment = data
      } catch (err) {
        console.error(err)
      }
    },
    async addCompanyCenter (val) {
      try {
        await CompanyCenterService.createGroup(val)
        await this.getCompanyCenter()
        this.resetFormAndToggleSideBar(this.$refs.CompanyCenterAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Company Center has been added'
          }
        })
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Company Center has been Failed'
          }
        })
      }
    },
    async updateCompanyCenter (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await CompanyCenterService.updateGroup(val.id, payload)
        await this.getCompanyCenter()
        this.setDataInitialAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Company Center has been updated'
          }
        })
      } catch (err) {
        console.log(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Update Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Company Center has been Failed'
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await CompanyCenterService.deleteGroup(val.ID)
        await this.getCompanyCenter()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Company Center Type has been deleted'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Company Center has been Failed ${error}`
          }
        })
      }
    },
    async activeSidebar () {
      await this.getCompanyGroup()
      await this.getCompanyDepartment()
      this.isSidebarActive = true
    },
    async rowClick (val) {
      await this.getCompanyGroup()
      await this.getCompanyDepartment()
      this.selectedItem = { ...val }
      this.initialCompanyCenter = { ...val }
      this.isSidebarDetailActive = true
    },
    setDataInitialAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialCompanyCenter = { ...val }
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    fetchFilter () {
      this.setPagination(1)
      this.getCompanyCenter()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getCompanyCenter()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}
</style>
